<template>
  <div
    class="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless"
  >
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Evolução Consentimentos</span
          >
          <span class="d-block text-dark-50 mt-2 font-size-sm"
            >Mais de 500 novos consentimentos</span
          >
        </h3>
      </div>
      <!--end::Title-->
      <!--begin::Toolbar-->
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'month' }"
              @click="show = 'month'"
            >
              <span class="nav-text font-size-sm">Mês</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'week' }"
              @click="show = 'week'"
            >
              <span class="nav-text font-size-sm">Semana</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'day' }"
              @click="show = 'day'"
            >
              <span class="nav-text font-size-sm">Dia</span>
            </a>
          </li>
        </ul>
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="dataToShow"
        type="bar"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-5",
  components: {},
  data() {
    return {
      show: "day",
      chartOptions: {},
      month: [
        {
          name: "Consentimentos",
          data: [1400, 1234, 921, 839, 1540, 1138]
        },
        {
          name: "Visualizações",
          data: [2341, 1987, 2131, 1742, 2569, 1838]
        }
      ],
      day: [
        {
          name: "Consentimentos",
          data: [44, 34, 57, 21, 61, 58]
        },
        {
          name: "Visualizações",
          data: [60, 85, 70, 34, 87, 105]
        }
      ],
      week: [
        {
          name: "Consentimentos",
          data: [44, 34, 57, 56, 61, 44]
        },
        {
          name: "Visualizações",
          data: [76, 55, 101, 98, 80, 105]
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    dataToShow() {
      if (this.show === "month") return this.month;
      if (this.show === "week") return this.week;
      if (this.show === "day") return this.day;
      return this.day;
    }
  },
  mounted() {
    // reference; kt_mixed_widget_1_chart
    this.chartOptions = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: ["Nov", "Dez", "Jan", "Fev", "Mar", "Abr"],
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return val + " ";
          }
        }
      },
      colors: [
        this.layoutConfig("colors.theme.base.success"),
        this.layoutConfig("colors.theme.base.danger")
      ],
      grid: {
        borderColor: this.layoutConfig("colors.gray.gray-200"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    };
  }
};
</script>
<style scoped>
.nav-link {
  cursor: pointer;
}
</style>
